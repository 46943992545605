$light-blue: #03c;
$lighter-blue: #38f;
$light-green: #3f6;
$dark-green: #242;
$light-red: #e00;
$dim-white: #f0f0f0;
$dark-gray: #222;

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: $dark-gray;
  color: $dim-white;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}

.menuButton {
  cursor: pointer;
  border: 3px solid $dim-white;
  border-radius: 25px;
  font-size: 20px;
  display: table;
  padding: 10px;
  margin: 10px auto;
  background-color: $lighter-blue;
  color: $dim-white;
  white-space: nowrap;
  width: 200px;
  text-align: center;

  &.backButton {
    background-color: $light-red;
    margin-bottom: auto;
  }
}

.menuSpacer {
  height: 30px;
  margin: auto;

  &.noTop {
    margin-top: 0;
  }

  &.noBottom {
    margin-bottom: 0;
  }
}

.scoreEntry {
  margin: 10px auto;
  text-align: center;
  font-size: 15px;
  border: 1px solid $dim-white;
  border-radius: 10px;
  padding: 10px;
  width: 200px;

  &.easy {
    background-color: $light-blue;
  }

  &.medium {
    background-color: #0a0;
  }

  &.hard {
    background-color: #a33;
  }

  .difficulty {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

@keyframes justFilled {
  from {
    background-color: $lighter-blue;
    font-size: 30px;
  }

  to {
    background-color: $dark-gray;
    font-size: 20px;
  }
}

@keyframes justFilledActive {
  from {
    background-color: $lighter-blue;
    font-size: 30px;
  }

  to {
    background-color: $dark-green;
    font-size: 20px;
  }
}

@keyframes justErrored {
  from {
    background-color: $light-red;
  }

  to {
    background-color: $dark-gray;
  }
}

@keyframes justErroredActive {
  from {
    background-color: $light-red;
  }

  to {
    background-color: $dark-green;
  }
}

.gameScreen {
  display: table;
  margin: auto;

  .header {
    font-size: 24px;
    margin: 10px 0;
    overflow: hidden;
    height: 30px;

    .leftSide {
      display: inline-block;
    }

    .backButton {
      cursor: pointer;
      border: 2px solid $dim-white;
      border-radius: 25px;
      font-size: 12px;
      display: inline-block;
      padding: 5px;
      background-color: $light-red;
      color: $dim-white;
      white-space: nowrap;
      width: 50px;
      text-align: center;
      overflow: hidden;
    }

    .rightSide {
      float: right;
      text-align: right;
    }

    .timerContainer {
      display: inline-block;
    }

    .playPauseContainer {
      display: inline-block;
      margin-left: 5px;

      .playButton,
      .pauseButton {
        display: inline-block;

        svg {
          width: 1em;
          height: 0.9em;
          vertical-align: -2px;
        }
      }
    }
  }

  .grid {
    border-collapse: collapse;
    width: 424px;
    height: 424px;
    table-layout: fixed;

    tr {
      &:nth-child(3n + 1) {
        td {
          border-top-width: 4px;
        }
      }

      &:nth-child(3n) {
        td {
          border-bottom-width: 4px;
        }
      }

      td {
        border: 2px solid #fff;
        color: $dim-white;
        text-align: center;
        vertical-align: middle;
        padding: 2px;
        font-size: 20px;
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;

        &:nth-child(3n + 1) {
          border-left-width: 4px;
        }

        &:nth-child(3n) {
          border-right-width: 4px;
        }

        &.matchesSelection {
          // color: $light-green;
          background-color: $dark-green;
        }

        &:focus-visible {
          outline: none;
        }

        &.justFilled {
          animation-name: justFilled;
          animation-duration: 1s;

          &.matchesSelection {
            animation-name: justFilledActive;
          }
        }

        &.error {
          animation-name: justErrored;
          animation-duration: 1s;

          &.matchesSelection {
            animation-name: justErroredActive;
          }
        }

        &.singleHint {
          box-shadow: inset 0 0 4px 4px $lighter-blue;

          &.matchesSelection {
            box-shadow: inset 0 0 4px 4px $light-green;
          }
        }

        .hintTable {
          margin: auto;
          width: 36px;
          height: 36px;
          table-layout: fixed;

          td {
            width: 33%;
            min-width: 33%;
            max-width: 33%;
            height: 33%;
            min-height: 33%;
            max-height: 33%;
            border: none;
            padding: 0;
            font-size: 9px;
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .numberBar {
    font-size: 24px;
    width: 100%;
    text-align: center;
    margin-top: 10px;

    .selected {
      color: $light-green;
    }

    .even {
      padding-top: 30px;
    }

    .odd {
      padding-bottom: 30px;
    }

    .completed {
      color: transparent;
    }
  }

  .pausedGrid {
    width: 424px;
    height: 424px;
    position: relative;
    box-sizing: border-box;
    border: 4px solid #fff;

    .pausedGridIcon {
      width: 200px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -100px;

      svg {
        width: 200px;
        height: 200px;
      }
    }
  }

  .doneGrid {
    width: 424px;
    height: 424px;
    position: relative;
    box-sizing: border-box;
    border: 4px solid #fff;

    .doneGridIcon {
      width: 200px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -100px;
      color: $light-green;

      svg {
        width: 200px;
        height: 200px;
      }
    }
  }
}

.postGame {
  .newHighScore {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
    color: $light-green;
    height: 50px;
  }

  .noNewHighScore {
    height: 50px;
    margin-bottom: 10px;
  }

  .scoreInfo {
    text-align: center;
    border: 2px solid $dim-white;
    margin: 10px auto;
    padding: 20px 10px;
    border-radius: 10px;
    width: 200px;

    .difficulty {
      font-size: 24px;
      margin-bottom: 5px;
    }
  }
}

.settingsMenu {
  .infoPanel {
    width: auto;
    margin: 0 auto;
    border: 2px solid $dim-white;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px;

    .infoTable {
      border-collapse: collapse;
      text-align: center;

      th,
      td {
        padding: 5px;
      }

      th {
        font-size: 24px;
      }

      .buildId {
        td {
          padding-bottom: 20px;
        }
      }
    }
  }
}